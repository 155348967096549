:root {
  --body-large-font-family: "Roboto", Helvetica;
  --body-large-font-size: 16px;
  --body-large-font-style: normal;
  --body-large-font-weight: 400;
  --body-large-letter-spacing: 0.5px;
  --body-large-line-height: 24px;
  --collection-1-black: rgba(0, 0, 0, 1);
  --collection-1-light-gray: rgba(189, 189, 189, 1);
  --collection-1-primary: rgba(94, 87, 216, 1);
  --collection-1-secondary: rgba(147, 232, 190, 1);
  --colors-neutral-neutral-0: rgba(0, 0, 0, 1);
  --colors-neutral-neutral-100: rgba(255, 255, 255, 1);
  --colors-neutral-neutral-15: rgba(37, 37, 41, 1);
  --colors-neutral-neutral-30: rgba(70, 70, 74, 1);
  --colors-neutral-neutral-40: rgba(94, 94, 98, 1);
  --colors-neutral-neutral-50: rgba(119, 118, 122, 1);
  --colors-neutral-neutral-80: rgba(199, 198, 202, 1);
  --colors-primary-primary-100: rgba(247, 249, 254, 1);
  --colors-primary-primary-30: rgba(0, 62, 168, 1);
  --colors-primary-primary-35: rgba(0, 72, 193, 1);
  --colors-primary-primary-40: rgba(20, 84, 212, 1);
  --colors-primary-primary-98: rgba(232, 239, 253, 1);
  --colors-secondary-secondary-70: rgba(253, 143, 64, 1);
  --colors-secondary-secondary-95: rgba(255, 237, 228, 1);
  --label-large-font-family: "Roboto", Helvetica;
  --label-large-font-size: 14px;
  --label-large-font-style: normal;
  --label-large-font-weight: 500;
  --label-large-letter-spacing: 0.10000000149011612px;
  --label-large-line-height: 20px;
  --lighton-surface-variant: rgba(73, 69, 79, 1);
  --palette-action-active: rgba(0, 0, 0, 0.56);
  --roboto-10px-regular-font-family: "Roboto", Helvetica;
  --roboto-10px-regular-font-size: 10px;
  --roboto-10px-regular-font-style: normal;
  --roboto-10px-regular-font-weight: 400;
  --roboto-10px-regular-letter-spacing: 0px;
  --roboto-10px-regular-line-height: normal;
  --roboto-13px-regular-font-family: "Roboto", Helvetica;
  --roboto-13px-regular-font-size: 13px;
  --roboto-13px-regular-font-style: normal;
  --roboto-13px-regular-font-weight: 400;
  --roboto-13px-regular-letter-spacing: 0px;
  --roboto-13px-regular-line-height: normal;
  --roboto-16px-regular-font-family: "Roboto", Helvetica;
  --roboto-16px-regular-font-size: 16px;
  --roboto-16px-regular-font-style: normal;
  --roboto-16px-regular-font-weight: 400;
  --roboto-16px-regular-letter-spacing: 0px;
  --roboto-16px-regular-line-height: normal;
  --roboto-20px-regular-font-family: "Roboto", Helvetica;
  --roboto-20px-regular-font-size: 20px;
  --roboto-20px-regular-font-style: normal;
  --roboto-20px-regular-font-weight: 400;
  --roboto-20px-regular-letter-spacing: 0px;
  --roboto-20px-regular-line-height: normal;
  --roboto-25px-regular-font-family: "Roboto", Helvetica;
  --roboto-25px-regular-font-size: 25px;
  --roboto-25px-regular-font-style: normal;
  --roboto-25px-regular-font-weight: 400;
  --roboto-25px-regular-letter-spacing: 0px;
  --roboto-25px-regular-line-height: normal;
  --roboto-31px-regular-font-family: "Roboto", Helvetica;
  --roboto-31px-regular-font-size: 31px;
  --roboto-31px-regular-font-style: normal;
  --roboto-31px-regular-font-weight: 400;
  --roboto-31px-regular-letter-spacing: 0px;
  --roboto-31px-regular-line-height: normal;
  --roboto-39px-regular-font-family: "Roboto", Helvetica;
  --roboto-39px-regular-font-size: 39px;
  --roboto-39px-regular-font-style: normal;
  --roboto-39px-regular-font-weight: 400;
  --roboto-39px-regular-letter-spacing: 0px;
  --roboto-39px-regular-line-height: normal;
  --spacing-radius-none: 0px;
  --spacing-radius-sm: 2px;
  --spacing-space-1: 4px;
  --spacing-space-2: 8px;
  --spacing-space-3: 12px;
  --spacing-space-4: 16px;
  --spacing-space-6: 24px;
  --state-layerslightprimaryopacity-008: rgba(80, 93, 164, 0.08);
  --title-small-font-family: "Roboto", Helvetica;
  --title-small-font-size: 14px;
  --title-small-font-style: normal;
  --title-small-font-weight: 500;
  --title-small-letter-spacing: 0.10000000149011612px;
  --title-small-line-height: 20px;
}

/*

To enable a theme in your HTML, simply add one of the following data attributes to an HTML element, like so:

<body data-palette-mode="light">
    <!-- the rest of your content -->
</body>

You can apply the theme on any DOM node, not just the `body`

*/

[data-palette-mode="light"] {
  --palette-action-active: rgba(0, 0, 0, 0.56);
}

[data-palette-mode="dark"] {
  --palette-action-active: rgba(255, 255, 255, 0.56);
}

[data-colors-mode="light"] {
  --colors-primary-primary-100: rgba(247, 249, 254, 1);
  --colors-primary-primary-98: rgba(232, 239, 253, 1);
}

[data-colors-mode="light-high-contrast"] {
  --colors-primary-primary-100: rgba(255, 255, 255, 1);
  --colors-primary-primary-98: rgba(250, 248, 255, 1);
}

[data-colors-mode="light-medium-contrast"] {
  --colors-primary-primary-100: rgba(255, 255, 255, 1);
  --colors-primary-primary-98: rgba(250, 248, 255, 1);
}

[data-colors-mode="dark"] {
  --colors-primary-primary-100: rgba(255, 255, 255, 1);
  --colors-primary-primary-98: rgba(250, 248, 255, 1);
}

[data-colors-mode="dark-high-contrast"] {
  --colors-primary-primary-100: rgba(255, 255, 255, 1);
  --colors-primary-primary-98: rgba(250, 248, 255, 1);
}

[data-colors-mode="dark-medium-contrast"] {
  --colors-primary-primary-100: rgba(255, 255, 255, 1);
  --colors-primary-primary-98: rgba(250, 248, 255, 1);
}
